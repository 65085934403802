<template>
  <div class="ant-pro-footer-toolbar" :style="{ width: barWidth, transition: '0.3s all' }">
    <div class="btn-group flexbox middle center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterToolBar',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    siderWidth: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    barWidth () {
      return `calc(100% - ${this.collapsed ? 80 : this.siderWidth || 200}px)`
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-footer-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 56px;
  line-height: 56px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding: 0 24px;
  z-index: 1000;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.btn-group {
  height: 56px;
}
</style>
