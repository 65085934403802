<template>
  <div class="p-container">
    <a-list item-layout="horizontal" :data-source="tableList">
      <a-list-item slot="renderItem" slot-scope="item">
        <div class="info-wrap flexbox middle justify">
          <div class="item-info flexbox flex1">
            <img
              class="p-img"
              :src="item.coverUrl ? item.coverUrl : item.coverImg"
              alt=""
            />
            <div class="p-info flex1">
              <div class="p-wrap">
                <div class="p-info-name more-t">
                  {{ item.name ? item.name : item.businessName || item.memberName }}
                </div>
                <div
                  v-show="item.courseType == 1 && item.pricingType == 0"
                  class="p-info-price"
                >
                  免费
                </div>
                <div
                  v-show="item.courseType == 1 && item.pricingType == 1"
                  class="p-info-price"
                >
                  ￥{{ item.price.toFixed(2) }}
                </div>
                <div
                  v-show="item.courseType == 1 && item.pricingType == 2"
                  class="p-info-price"
                >
                  不支持单独售卖
                </div>
                <div v-show="item.courseType != 1" class="p-info-price">
                  ￥{{ item.price.toFixed(2) }}
                </div>
              </div>
              <div class="p-info-type">
                商品类型 ：{{ businessTypeMap.get( item.courseType ? item.courseType : item.businessType) }}
              </div>
            </div>
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
export const businessTypeMap = new Map([
  [ 1, '课程'],
  [ 2, '会员'],
  [ undefined,'会员']
])
export default {
  name: 'ProductCard',
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      businessTypeMap
    }
  }
}
</script>
<style lang="less" scoped>
.p-container {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
}
.info-wrap {
  width: 100%;
  .item-info {
    margin-right: 14px;
    .p-img {
      max-width: 100px;
      max-height: 85px;
      object-fit: contain;
      border-radius: 2px;
    }
    .p-info {
      margin-left: 10px;
      .p-wrap {
        height: 67px;
      }
      .p-info-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
      }
      .p-info-price {
        font-size: 14px;
        color: #fb6161;
        margin-top: 4px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .p-info-type {
        font-size: 12px;
        color: #888;
        margin: 0 14px 0 0;
      }
    }
  }
}
</style>