<template>
  <!-- 上传图片组件 -->
  <div>
    <div class="maxBox">
      <div class="imgClass">
        <template v-if="imgUrl.length > 0 || readOnly">
          <div class="box" v-for="(item, index) in imgUrl" :key="index" @mouseenter="handleMouse(index)"
            @mouseleave="indexChange = -1">
            <img :src="item" alt="">
            <div v-if="!readOnly" class="mark" v-show="indexChange == index" @click="circleClick(index)">删除
            </div>
          </div>
        </template>
        <div class="updClass" v-if="imgUrl.length < imageUrlNum">
          <a-icon type="plus" />
          <span>上传图片</span>
          <input type="file" ref="upFile" accept="image/png,image/jpg,image/jpeg" @change="File()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domainName } from "@/config/index";
import axios from "axios";
import { message } from "ant-design-vue";

export default {
  props: {
    imageUrlNum: {
      type: Number,
      default: 1,
    },
    imgUrl: {
      type: Array,
      default: [],
    },
    avatarTextBottom: {
      type: String,
      default: "图片小于1M",
    },
    urlSize: {
      type: Number,
      default: 500,
    },
    sizeWh: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false,
      indexChange: -1,
      action: `${domainName}/admin/files/upload/images`,
    };
  },
  methods: {
    circleClick (index) {
      this.imgUrl.splice(index, 1)
      this.$emit("avatarfns", this.imgUrl)
    },

    handleMouse (index) {
      this.indexChange = index
    },

    File () {
      let maxSize = this.urlSize
      let files = this.$refs.upFile.files[0]
      // files.size 这里返回的是b 字节
      if (files.size > maxSize * 1024) {
        const size = this.urlSize >= 1024 ? (this.urlSize / 1024) + 'M' : `${maxSize}k`
        message.warning(`图片大小不能超过${size}`)
        return false
      }
      var reader = new FileReader();
      reader.readAsDataURL(files); //调用自带方法进行转换
      reader.onload = (e) => {
        if (this.sizeWh == 1) {
          let width = 0
          let height = 0
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            width = this.width
            height = this.height
          };
          setTimeout(() => {
            console.log(width, height)
            if (width > 650 || height > 1000) {
              message.warning("请上传小于等于650*1000图片！");
              return false
            } else {
              let file = this.dataURLtoFile(e.target.result, files.name);
              let formData = new window.FormData();
              formData.append("uploadFile", file);
              axios.post(`${domainName}/admin/files/upload/images`, formData).then((res) => {
                if (res.data.code == 200) {
                  this.imgUrl.push(res.data.data.url)
                  this.$emit("avatarfns", this.imgUrl);
                }
              });
            }
          }, 1000)
        } else {
          let file = this.dataURLtoFile(e.target.result, files.name);
          let formData = new window.FormData();
          formData.append("uploadFile", file);
          axios.post(`${domainName}/admin/files/upload/images`, formData).then((res) => {
            if (res.data.code == 200) {
              this.imgUrl.push(res.data.data.url)
              this.$emit("avatarfns", this.imgUrl);
            }
          });
        }
      };
    },

    GetPercent (num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total = (Math.round(num / total * 100) / 100.00);
    },

    // base 64 转成二进制文件流
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },

  mounted () {
  }
};
</script>

<style lang="less" scoped>
.maxBox {
  display: flex;
  justify-content: flex-start;

  .imgClass {
    display: flex;
    flex-wrap: wrap;

    .box {
      overflow: hidden;
      width: 102px;
      height: 102px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      padding: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .mark {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, .6);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        flex-wrap: 500;
        color: #fff;
        text-decoration: underline
      }
    }

    .updClass {
      width: 102px;
      height: 102px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      font-size: 12px;
      overflow: hidden;
      position: relative;

      input {
        width: 102px;
        height: 102px;
        position: absolute;
        cursor: pointer;
        outline: medium none;
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        opacity: 0;
        z-index: 100;
      }
    }
  }
}
</style>
