/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2021-12-02 22:01:02
 * @Description: 常量管理
 */
export const AgentLevel = {
    1: '市级代理',
    2: '县级代理',
    3: '站点代理'
};

export const urlReg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;

export const wechatApplet = [
  {
    name:'领读人申怡',
    appid:'gh_5ce520c9a8c9'
  },
  {
    name:'领读人申怡预约',
    appid:'wx4be3bb2d54d418f7'
  }
]

export const appidList = [
  {
    name: '领读人申怡每日预约',
    id: 'gh_5ce520c9a8c9'
  },
  {
    name: '领读人申怡预约',
    id: 'gh_d91a1cea8408'
  },
  {
    name: '申怡读书用户服务',
    id: 'gh_c17c0b48d2da'
  },
  {
    name: '申怡学堂',
    id: 'gh_6f903850dde0'
  }
]