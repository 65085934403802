<template>
  <!-- 新建一级页面 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 8 }"
   >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :maxLength="32" :disabled="readonly">
          <div slot="addonAfter">{{ form.name.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        label="封面图"
        prop="cover"
        class="upload_img"
        :class="{ 'has-error': !form.cover }"
     >
        <UpdataImg
          @avatarfns="data => form.cover = data[0]" 
          :imageUrlNum="1" 
          :imgUrl="form.cover ? [form.cover] : []"
          :urlSize="2048"
          :read-only="readonly"
        />
        <div class="ant-form-explain" v-if="!form.cover">
          请选择封面图
        </div>
      </a-form-model-item>
      <a-form-model-item label="宽度" prop="width">
        <a-input-number v-model="form.width" :min="1" :disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item label="高度" prop="height">
        <a-input-number v-model="form.height" :min="1" :disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item label="跳转方式" prop="targetType" :wrapper-col="{ span: 16 }">
        <a-radio-group
          name="radioGroup"
          :value="form.targetType"
          :disabled="readonly"
          @change="targetTypeChange"
       >
          <a-radio :value="1"> 课程 </a-radio>
          <a-radio :value="5"> 小程序 </a-radio>
          <a-radio :value="4"> 会员详情 </a-radio>
          <a-radio :value="6"> 抖音 </a-radio>
          <a-radio :value="2"> 跳转链接 </a-radio>
          <!-- 抖音 -->
          <div v-if="form.targetType === 6" class="dou-form">
            <div>
              <span class="label">抖音：</span>
              <a-select class="default-width mt-10" v-model="form.tiktokType" :disabled="readonly">
                <a-select-option :value="1">直播间</a-select-option>
                <a-select-option :value="2">用户主页</a-select-option>
              </a-select>
            </div>
            <div class="mt-10 pos-relative">
              <span class="label">ID：</span>
              <a-input v-model="form.targetText" :disabled="readonly" class="default-width" :class="{'errno-border': validate.targetText}" placeholder="请输ID" @blur="validateValue('targetText')" />
              <span v-show="validate.targetText" class="errno-msg">ID不能为空</span>
            </div>
          </div>
          <!-- 跳转链接 -->
          <div v-if="form.targetType === 2" class="mt-10 pos-relative">
            <a-input v-model="form.targetText" :disabled="readonly" class="default-width" :class="{'errno-border': validate.url}" placeholder="请输入跳转链接" @blur="validateValue('url')" />
            <span v-show="validate.targetText" class="errno-msg">跳转链接不能为空</span>
          </div>
          <!-- 小程序 -->
          <div v-if="form.targetType === 5" class="mt-10">
            <a-select class="default-width mt-10" v-model="form.targetText" :disabled="readonly">
              <a-select-option v-for="item in wechatApplet" :key="item.appid" v-model="item.appid">{{ item.name }}</a-select-option>
            </a-select>
          </div>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.targetType === 1" label="添加课程" prop="targetText">
        <a-button type="primary" :disabled="readonly" @click="modalVisible = true">{{ isAdd ? '添加课程' : '修改课程'}}</a-button>
        <div v-if="tableList.length > 0">
          <ProductCard :table-list="tableList" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="有效期" prop="datePicker">
        <a-range-picker v-model="form.datePicker" show-time format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate" :disabled="readonly" :placeholder="['开始时间', '结束时间']" style="width: 410px;" @change="timeChange" />
      </a-form-model-item>
      <a-form-model-item label="发布设置" prop="state" required>
        <a-radio-group v-model="form.state" :disabled="readonly">
          <a-radio :style="radioStyle" :value="1">立即发布 </a-radio>
          <a-radio :style="radioStyle" :value="2"> 暂不发布 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
      <a-button
        v-if="!readonly" 
        type="primary"
        @click="handleSave"
        :loading="keepLoading"
        :disabled="keepLoading"
       >保存</a-button
     >
    </FooterToolBar>

    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :type="0"
      :isShowMember="false"
      :selectedRowKeys="selectedRowKeys"
      @cancel="modalVisible = false"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar"
import { baseMixin } from "@/store/app-mixin"
import UpdataImg from "@/components/file/updataImg";
import ProductCard from '@/components/product/ProductCard'
import { disabledDate } from '@/utils/tools'
import { wechatApplet } from '@/utils/constants'
import moment from "moment"
import { AddHomeActiveAPI, EditHomeActiveAPI, GetHomeActiveDetailAPI } from '@/request/api/indexManage'
import RadioModal from "@/components/courseMember/newRadioModal";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar, UpdataImg, ProductCard, RadioModal },
  data() {
    return {
      wechatApplet,
      disabledDate,
      readonly: false,
      isAdd: true,
      tableList: [],
      keepLoading: false, // 保存的按钮是否加载
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      form: {
        name: "", //
        cover: '',
        width: '',
        height: '',
        targetType: 1,
        targetText: '', //分类
        tiktokType: 1,
        startTime: '',
        endTime: '',
        state: 1,
        id: '',
        datePicker: []
      },
      rules: {
        name: [
          { required: true, message: "输入的内容不能为空", trigger: "blur"},
          { max: 32, message: "长度最长为32个", trigger: "blur" }
        ],
        cover: [
          { required: true, message: '封面图不能为空', trigger: 'change' },
        ],
        width: [
          { required: true, message: '封面图宽度不能为空', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
        ],
        height: [
          { required: true, message: '封面图高度不能为空', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
        ],
        targetType: [{ required: true, message: '请选择分类', trigger: 'change'}],
        datePicker: [{ required: true, message: '请选择时间', trigger: ['change', 'blur']}],
        targetText: [{ required: true, message: '请选择课程', trigger: 'change'}]
      },
      targetTextRule:[{ required: true, message: '请选择课程', trigger: 'change'}],
      modalVisible: false,
      validate: {
        targetText: false
      },
      urlErrMsg: '',
      headerOptions: {
        maxImgSize:"20000",
        autoCrop: false, //是否默认生成截图框
        autoCropWidth: 400, //默认生成截图框宽度
        autoCropHeight: 400, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
      selectedRowKeys: [],
    };
  },
  created() {
    let id = this.$route.params.id
    this.readonly = this.$route.query.readonly === 'true'
    if (+id !== 0) {
      this.form.id = id
      this.isAdd = false
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const { code, data } = await GetHomeActiveDetailAPI({ id: this.form.id })
      if (code !== 200) return
      this.tableList = [data.businessData]
      const datePicker = [
        data.startTime,
        data.endTime
      ]
      this.form.datePicker = datePicker
      this.form = Object.assign(this.form, data)
    },
    targetTypeChange(e) {
      this.form.targetType = e.target.value
      this.rules.targetText = this.form.targetType === 1 ? this.targetTextRule : null
      this.form.targetText = this.form.targetType === 5 ? this.wechatApplet[0].appid : ''
      if (this.form.targetType === 6) {
        this.form.tiktokType = this.form.tiktokType === 0 ? 1 : this.form.tiktokType
      }
    },
    timeChange(times) {
      this.form.datePicker = times
      if (times.length === 0) return
      const start = moment(times[0]).format('yyyy-MM-DD HH:mm:ss')
      const end = moment(times[1]).format('yyyy-MM-DD HH:mm:ss')
      this.form.startTime = start
      this.form.endTime = end
    },
    // 保存
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (this.form.targetType === 6 || this.form.targetType === 5 || this.form.targetType === 2) {
          this.validate.targetText = !this.form.targetText
          if (this.validate.targetText) return
        }
        if (this.form.targetType !== 6) delete this.form.tiktokType
        if (!valid) return
        this.isAdd ? this.addHomeActive() : this.editHomeActive()
      })
    },
    async addHomeActive() {
      const params = {
        ...this.form
      }
      const { code } = await AddHomeActiveAPI(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$router.push('/indexManage/indexOperate')
    },
    async editHomeActive() {
      const params = {
        ...this.form
      }
      const { code } = await EditHomeActiveAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/indexManage/indexOperate')
    },
    validateValue(key) {
      this.validate[key] = !this.form[key] 
    },
    // 弹窗中确认按钮事件
    handleOk(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.tableList = [selectedRows]
      this.$set(this.form, 'targetText', selectedRowKeys[0])
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.form_span {
  top: -4px;
}
/deep/ .ant-form-item-control {
  line-height: unset;
}
.product-box {
  position: relative;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: red;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.edu-type .ant-form-item-children {
  display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
  margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
  width: 100%;
}
/deep/.date-picker .ant-form-item-children {
  display: flex;
  align-items: center;
}
/deep/.ant-form-item-control {
  line-height: 22px;
}
.dou-form {
  .label {
    display: inline-block;
    width: 50px;
  }
  .errno-msg {
    left: 50px;
  }
}
.default-width {
  width: 250px;
}
.mt-10 {
  margin-top: 10px;
}
.pos-relative {
  position: relative;
}
.errno-msg {
  position: absolute;
  color: #f5222d;
  font-size: 14px;
  left: 0;
  top: 32px;
}
.errno-border {
  border-color: #f5222d;
}
</style>
